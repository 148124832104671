/* https://colorhunt.co/palette/071a5208697217b978a7ff83 */
/* #222831 #086972 */
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
:root{
  --primary-color: #222831;
  --white-color: #ffffff;
  --black-color: #000000;
  --gray-color: #F5F3F3;
  --tag-badge: #FF0B37;
  --bold-color: #4d4d50;
  --green-color: #008000;
  --secondary-color: #A9A9A9;
  --nonactive-color: #ebe9e9;
  --primary-pallate: #FFD369;
}
body{
  background: #ffffff;
  width: 100%;
  scroll-behavior: smooth;
  overflow-x: hidden;
}
a{
  color: var(--primary-pallate) !important;
  text-decoration: none !important;
}
a:hover{
  color:rgb(241, 195, 86) !important;
}
a:visited{
  color:rgb(241, 195, 86) !important;
}
.bg-my-yellow{
  background-color: var(--primary-pallate);
}
.bg-full-primary{
  background-color: var(--primary-color);
}
.bg-myprimary{
  background-color: #a9a9a91f;
}
.text-yellow{
  color: var(--primary-pallate)
}
.text-yellow-full{
  color: #ffc949;
}
@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media (prefers-reduced-motion: no-preference) {
  .justgood-logo {
    animation: logo-spin infinite 20s linear;
  }
}
.main-page{
  width: 100%;
  min-height: 100vh;
  height: auto;
  background-color: var(--white-color);
  position: relative;
}
.home-page{
  background-color: var(--white-color);
  position: relative;
}
.home-page .first-column {
  width: auto;
  background-color: #181b1f;
  height: 200px;
  left: 0px;
  top: 0px;
}

.home-page .first-column .title-box{
  display: block;
  padding-top: 20px;
  padding-left: 20px;
  text-transform: uppercase;
}

.home-page .first-column .title-box .box-text1{
  border: 1px solid var(--primary-pallate);
  margin-bottom: 10px;
}
.home-page .first-column .title-box .text-title{
  font-family: 'Poppins';
  font-size: 17px;
  text-align: center;
  color: #ffffff;
}
.home-page .first-column .title-box .text-title2{
  font-family: 'Poppins';
  font-size: 25px;
  color: #ffffff;
  font-weight: bold;
  line-height: 17px;
  padding-top: 10px;
}
.home-page .first-column .title-box .text-title3{
  font-family: 'Poppins';
  font-size: 13px;
  color: var(--primary-pallate);
  /* animation: blink 3s linear infinite; */
  padding-top: 6px;
}
@keyframes blink{
  0%{opacity: 0;}
  50%{opacity: .5;}
  100%{opacity: 1;}
  }
.home-page .second-column {
  display: flex;
}
.home-page .second-column .user-text{
  font-family: 'Poppins';
  font-size: 20px;
  color: var(--primary-color);
  font-weight: bold;
}
.home-page .second-column .badge-text{
  font-size: 20px;
  color: var(--green-color);
}
.home-page .second-column .label{
  background-color: var(--white-color);
  border: 1px solid var(--secondary-color)
}
.home-page .second-column .label .text-label{
  font-family: 'Poppins';
  font-size: 14px;
  text-align: center;
  color: var(--primary-color);
  font-weight: bold;
}
.home-page .second-column .text{
  font-size: 14px;
  color: var(--primary-color)
}
.image-rounded{
  border-radius: 100px;
  padding: 3px;
}
.divider{
  margin-bottom: 5px;
  border-top: 1px solid #c7c7c7;
}
.divider-list{
  border-bottom: 1px solid #c7c7c7;
}
.global-title{
  font-family: 'Poppins';
  font-size: 16px;
  color: var(--bold-color);
  font-weight: bold;
  text-transform: uppercase;
}

.btn-verify{
  background-color: var(--primary-pallate);
  border-radius: 5px !important;
  color: white !important;
  text-transform: uppercase;
  text-transform: uppercase;
  width: 100px;
  justify-content: center;
}
.btn-verivied{
  background-color: var(--green-color);
  border-radius: 5px !important;
  color: white !important;
  text-transform: uppercase;
  text-transform: uppercase;
  width: 100px;
  justify-content: center;
}
.btn-buy {
  background-image:linear-gradient(to right, rgb(255, 211, 105) 0%, rgb(248, 209, 118) 100%);
  padding: 5px 5px !important;
  text-align: center;
  width: 100px;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white !important;            
  box-shadow: 3px 3px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px !important;
  display: block;
}

.btn-buy:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff !important;
  text-decoration: none;
}
.btn-shadow{
  box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.25);
}
.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: var(--primary-pallate) !important;
  box-shadow: 0 0 0 0.25rem rgba(253, 229, 13, 0.25) !important;
}
.form-control:disabled {
  background-color: #e9ecef;
  opacity: 1;
  color: var(--bold-color);
}
.loop-ip .form-control {
  background-color: #fff;
}
.loop-ip .form-control:focus {
  border-color: var(--bold-color) !important;
  box-shadow: 0 0 0 0.25rem rgba(253, 224, 95, 0.144) !important;
}
.loop-ip-active .form-control {
  border-color: var(--primary-pallate) !important;
  background-color: #fff;
}
.loop-ip-active .form-control:focus {
  border-color: var(--primary-pallate) !important;
  box-shadow: 0 0 0 0.25rem rgba(253, 224, 95, 0.144) !important;
}

.box-form{
  height: 528px;
  margin: 20px;
  background-size: 200% auto;          
  /* border: 1px solid rgba(0, 0, 0, 0.055); */
  border-radius: 10px;
  width: auto;
  box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.25); 
}

.box-form .box-configuration{
  width: auto;
  height: 403px;
  overflow-y: scroll;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.055); */
}
.box-form .box-configuration::-webkit-scrollbar {
  width: 6px;
  border-radius: 10px;
  background-color: #a9a9a91f;
}

.box-form .box-configuration::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  border-radius: 10px;
}
.box-whitelist{
  height: 528px;
  margin: 20px;
  transition: 0.5s;
  background-size: 200% auto;          
  border-radius: 10px;
  width: auto;
  box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.25); 
}
.box-whitelist .data-list{
  width: auto;
  height: 403px;
  overflow-y: scroll;
  padding-top: -10px;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.055); */
}
.box-whitelist .data-list::-webkit-scrollbar {
  width: 6px;
  border-radius: 10px;
  background-color: #a9a9a91f;
}

.box-whitelist .data-list::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  border-radius: 10px;
}
.tab-header{
  padding-top: -10px;
}

.box-note{
  height: auto;
  margin-top: 20px;
  margin-bottom: 40px;
  background-size: 200% auto;          
  /* border: 1px solid rgba(0, 0, 0, 0.055);  */
  border-radius: 10px;
  width: auto;
  box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.25);
}
.box-note .text-key{
  line-height: 16px;
  font-size: 18px;
  font-weight: 500px;
  color: #000000;
  line-height: 14px;
  text-transform: capitalize;
}
.box-note .text-remark{
  font-size: 15px;
  color: var(--primary-color);
}
.home-page .box-footer {
  border-top: 2px solid var(--primary-pallate);
  width: auto;
  background-color: var(--primary-color);
  height: 150px;
  left: 0px;
  box-shadow: 0 -5px 5px rgba(0, 0, 0, 0.1);
}
.home-page .box-footer a{
  color: var(--white-color) !important;
  text-decoration: none !important;
}
.home-page .box-footer a:hover{
  color:rgb(241, 195, 86) !important;
}
.home-page .desktop-container{
  background-color: var(--primary-color);
  display: flex;
  height: auto;
  animation: fadein 3s;
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.4), 
    rgba(0, 0, 0, 0.4)
  ),url('./images/background.svg');
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
  /* box-shadow: 0 5px 5px rgba(0, 0, 0, 0.5); */
}
@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.home-page .desktop-container .image-desktop{
  height: 200px;
  width: 200px;
  border-radius: 100px;
  border: 1px solid var(--primary-pallate);
  animation: shamove 5s infinite;
}
.home-page .desktop-container .image-desktop{
  height: 200px;
  width: 200px;
  border-radius: 100px;
  border: 3px solid var(--primary-pallate);
  animation: shamove 5s infinite;
}
@keyframes shamove{
  50% { box-shadow: 2px 2px 15px 0px var(--primary-pallate);
    -webkit-box-shadow: 2px 2px 15px 0px var(--primary-pallate);
    -moz-box-shadow: 2px 2px 15px 0px var(--primary-pallate);
  }
}
.home-page .desktop-container .title-box{
  display: block;
  padding-top: 20px;
  padding-left: 20px;
  text-transform: uppercase;
}

.home-page .desktop-container .title-box .box-text1{
  border: 1px solid var(--primary-pallate);
  margin-bottom: 10px;
}
.home-page .desktop-container .title-box .text-title{
  font-family: 'Poppins';
  font-size: 19px;
  text-align: center;
  color: #ffffff;
}
.home-page .desktop-container .title-box .text-title2{
  font-family: 'Poppins';
  font-size: 30px;
  color: #ffffff;
  font-weight: bold;
  line-height: 17px;
  padding-top: 10px;
  text-transform: uppercase;
}
.home-page .desktop-container .title-box .text-title3{
  font-family: 'Poppins';
  font-size: 18px;
  color: var(--primary-pallate);
  /* animation: blink 3s linear infinite; */
  padding-top: 8px;
  text-transform: uppercase;
}

.home-page .desktop-verify{
  display: flex;
  height: auto;
  animation: fadein 3s;
  background-image:url('./images/bg-verify.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size:cover;
  /* 1a1f25 */
}
@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.home-page .desktop-verify .user-text{
  font-family: 'Poppins';
  font-size: 20px;
  color: var(--primary-color);
  font-weight: bold;
}
.home-page .desktop-verify .badge-text{
  font-size: 20px;
  color: var(--green-color);
}
.home-page .desktop-verify .label{
  background-color: var(--white-color);
  border: 1px solid var(--secondary-color)
}
.home-page .desktop-verify .label .text-label{
  font-family: 'Poppins';
  font-size: 14px;
  text-align: center;
  color: var(--primary-color);
  font-weight: bold;
}
.home-page .desktop-verify .text{
  font-size: 14px;
  color: var(--primary-color)
}

.home-page .ipcustom-container{
  height: auto;
}
.text-notfound{
  font-family: 'Poppins';
  font-size: 25px;
  color: var(--primary-color);
  font-weight: bold;
  line-height: 17px;
  animation: blink 3s linear infinite;
}
.text-title-desktop{
  font-family: 'Poppins';
  font-size: 17px;
  color: var(--primary-color);
  font-weight: bold;
  line-height: 16px;
  width: 155px;
  border: 1px solid var(--primary-color);
  border-radius: 10px;
}
